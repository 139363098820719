import * as React from 'react';
import "./View.css";
import * as QueryString from 'query-string'
import PDFView from './PDFView'
import IMGView from './IMGView'
import TXTView from './TXTView'
import DownloadView from './DownloadView'

class View extends React.Component<any, { file_path: string, file_type: string, download: boolean }> {
    constructor(props: any) {
        super(props);

        this.state = {
            file_path: '',
            file_type: '',
            download: false
        };

        this.renderSwitch = this.renderSwitch.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            window.history.pushState({}, "DMS View", "/view");
            //console.log(this.props.location.search)
            let { ref_path, id, token, file_name, read_only, download } = QueryString.parse(this.props.location.search);

            document.title = "DMS - " + file_name;

            fetch("/v1/env")
                .then(res => res.json())
                .then(
                    (result) => {
                        var resultData = result.apiUrl;

                        if (token != undefined) {
                            var fileExtension = '';
                            //let p = 'https://scg-dms-api.sonarinno.com/v1/dms/file?token=' + token
                            let p = resultData + '/v1/dms/file?token=' + token;
                            //let p = 'http://localhost:8080/v1/dms/file?token=' + token
                            if (read_only) {
                                if (String(read_only).toLowerCase() == 'true') {
                                    p += '&read_only=true'
                                }
                            }
                            if (id != undefined) {
                                p += '&id=' + id
                            }
                            if (ref_path != undefined) {
                                p += '&ref_path=' + ref_path
                            }
                            if (file_name != undefined) {
                                fileExtension = String(file_name).substr(String(file_name).lastIndexOf('.'));
                                p += '&file_name=' + file_name
                            }

                            var d = false
                            if (download) {
                                if (String(download).toLowerCase() == 'true') {
                                    d = true
                                }
                            }

                            this.setState({
                                file_path: p,
                                file_type: fileExtension,
                                download: d
                            });
                        }
                    },
                    (error) => {
                    }
                )
        }
    }

    renderSwitch(file_path: any, file_type: any, download: any) {
        if (download == true) {
            return <DownloadView filePath={file_path} />;
        }
        else {
            switch (file_type.toLowerCase()) {
                case '.pdf':
                    return <PDFView filePath={file_path} />;
                case '.txt':
                case '.json':
                    return <TXTView filePath={file_path} />;
                case '.png':
                case '.gif':
                case '.jpg':
                case '.jpeg':
                    return <IMGView filePath={file_path} />;
                default:
                    return <DownloadView filePath={file_path} />;
            }
        }
    }

    render() {
        const { file_path, file_type, download } = this.state
        if (!file_path) {
            return null;
        }

        return this.renderSwitch(file_path, file_type, download);
    }
}

export default View;