import React, { FormEvent, memo, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { FaSearch } from 'react-icons/fa';

export type SearchValues = {
    citizenId?: string;
    from?: Date | null;
    to?: Date | null;
};

type Props = {
    onSubmitSearch: (values: SearchValues) => void;
};

function AuthenticationHistorySearchBar(props: Props) {
    const { onSubmitSearch } = props;

    const [citizenId, setCitizenId] = useState<string>();
    const [from, setFrom] = useState<Date | null>();
    const [to, setTo] = useState<Date | null>();

    const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmitSearch({ citizenId: citizenId?.trim(), from, to });
    };

    return (
        <form onSubmit={handleSubmitSearch}>
            <Row>
                <Col>
                    <Card>
                        <Card.Header
                            className="h-50 d-inline-block"
                            style={{
                                marginBottom: '-12px',
                                marginTop: '-12px',
                            }}
                        >
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <Form.Label
                                    column
                                    sm="3"
                                >
                                    ค้นหาข้อมูล
                                </Form.Label>
                            </div>
                        </Card.Header>
                        <Card.Body style={{ overflow: 'none' }}>
                            <Row className="mt-2">
                                <Col xs={3}>
                                    <Form.Label
                                        column
                                        sm={12}
                                    >
                                        Citizen ID
                                    </Form.Label>
                                    <Form.Group controlId="company">
                                        <Form.Control
                                            value={citizenId}
                                            onChange={(event) =>
                                                setCitizenId(event.target.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Label
                                        column
                                        sm={12}
                                    >
                                        From
                                    </Form.Label>
                                    <Form.Group controlId="company">
                                        <DatePicker
                                            selectsStart
                                            selected={from}
                                            startDate={from}
                                            endDate={to}
                                            onChange={setFrom}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            customInput={<Form.Control />}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Label
                                        column
                                        sm={12}
                                    >
                                        Citizen ID
                                    </Form.Label>
                                    <Form.Group controlId="company">
                                        <DatePicker
                                            selectsEnd
                                            selected={to}
                                            startDate={from}
                                            endDate={to}
                                            minDate={from}
                                            onChange={setTo}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            customInput={<Form.Control />}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        type="submit"
                                        style={{
                                            marginBottom: '10px',
                                            marginRight: '10px',
                                            float: 'right',
                                        }}
                                        variant="outline-success"
                                        size="sm"
                                    >
                                        <FaSearch />
                                        &nbsp;ค้นหา
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </form>
    );
}

export default memo(AuthenticationHistorySearchBar);
